@-webkit-keyframes icon_change {
  from { color: #a7a7a7; }
  to { color: #f44336; }
}
@-moz-keyframes icon_change {
  from { color: #a7a7a7; }
  to { color: #f44336; }
}
@-ms-keyframes icon_change {
  from { color: #a7a7a7; }
  to { color: #f44336; }
}
@-o-keyframes icon_change {
  from { color: #a7a7a7; }
  to { color: #f44336; }
}
@keyframes icon_change {
  from { color: #a7a7a7; }
  to { color: #f44336; }
}

.hour-required {
   -webkit-animation: icon_change 1s infinite alternate;
   -moz-animation: icon_change 1s infinite alternate;
   -ms-animation: icon_change 1s infinite alternate;
   -o-animation: icon_change 1s infinite alternate;
   animation: icon_change 1s infinite alternate;
}
