@-webkit-keyframes color_change {
  from { background-color: #ffffff; }
  to { background-color: #ef5d5d; }
}
@-moz-keyframes color_change {
  from { background-color: #ffffff; }
  to { background-color: #ef5d5d; }
}
@-ms-keyframes color_change {
  from { background-color: #ffffff; }
  to { background-color: #ef5d5d; }
}
@-o-keyframes color_change {
  from { background-color: #ffffff; }
  to { background-color: #ef5d5d; }
}
@keyframes color_change {
  from { background-color: #ffffff; }
  to { background-color: #ef5d5d; }
}

.action-requiried {
/*     background-color: blue;
    border: 5px solid white;
    border-radius: 50px;
    width: 50px;
    height: 50px; */
   -webkit-animation: color_change 1s infinite alternate;
   -moz-animation: color_change 1s infinite alternate;
   -ms-animation: color_change 1s infinite alternate;
   -o-animation: color_change 1s infinite alternate;
   animation: color_change 1s infinite alternate;
}
